import React, {useEffect, useState} from "react";
import {Box, InputAdornment, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Language} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export const LangSwitcher = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const [language, setLanguage] = useState('en')

    const handleChangeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)
            .then(() => {
                navigate(e.target.value === 'en' ? '/' + e.target.value : '/', {replace: true})
            })
    }

    useEffect(() => {
        const currentLang = i18n.language ? i18n.language.split('-')[0] : 'en';
        setLanguage(['it', 'en', 'es'].includes(currentLang) ? currentLang : 'en');
    }, [i18n.language]);

    return (
        <Select sx={{width: '7rem'}} value={language}
                startAdornment={<InputAdornment position="start" ><Language  /></InputAdornment>}
                onChange={handleChangeLanguage}>
            <MenuItem value={'it'}>It</MenuItem>
            <MenuItem value={'en'}>En</MenuItem>
            <MenuItem value={"es"}>Es</MenuItem>
        </Select>
    )
}

// TOGGLE
/*
import React, { useEffect, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Language } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const LangSwitcher = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('en');

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'it' : 'en';
        i18n.changeLanguage(newLanguage)
            .then(() => {
                navigate(newLanguage === 'en' ? '/' + newLanguage : '/', { replace: true });
                setLanguage(newLanguage);
            });
    };

    useEffect(() => {
        const currentLang = i18n.language ? i18n.language.split('-')[0] : 'en';
        setLanguage(['it', 'en'].includes(currentLang) ? currentLang : 'en');
    }, [i18n.language]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: '4.5rem',
                padding: '0.5rem',
                borderRadius: '4px',
                backgroundColor: 'rgba(255, 255, 255, 1)'
            }}
            onClick={toggleLanguage}
        >
            <InputAdornment position="start">
                <Language />
            </InputAdornment>
            <span style={{color:'#131331'}}>{language === 'en' ? 'EN' : 'IT'}</span>
        </Box>
    );
};
*/

