import {useTranslation} from "react-i18next";
import {Box, Button, Container, Typography, useMediaQuery} from "@mui/material";
import PortfolioCard from "../components/PortfolioCard";
import {useEffect, useState} from "react";
import {portfolioCards} from "../data/portfolio";
import {useTheme} from "@mui/material/styles";
import ProjectModal from "../components/ProjectModal";
import {client} from "../sanity/client";

const Portfolio = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation();

    const [selectedCard, setSelectedCard] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);

    const [inEvidenza, setInEvidenza] = useState(null)
    const [bestEvents, setBestEvents] = useState([]);

    const [sanityProjects, setSanityProjects] = useState([]);

    //TEST SANITY
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const query = `*[_type == "project"]{
                    titolo,
                    traduzione,
                    background{
                      asset->{
                        url,
                        metadata {
                          dimensions
                        }
                      }
                    },
                    thumbnailBackground{
                      asset->{
                        url,
                        metadata {
                          dimensions
                        }
                      }
                    },
                    videoUrl,
                    immagini[]{
                      asset->{
                        url,
                        metadata {
                          dimensions
                        }
                      }
                    },
                    clienti[]{
                      asset->{
                        url,
                        metadata {
                          dimensions
                        }
                      }
                    },
                    inEvidenza
                  }`;
                const data = await client.fetch(query);
                console.info(data);
                setSanityProjects(data);
            } catch (error) {
                console.error('Errore durante il fetch dei progetti:', error);
            }
        };

        fetchProjects();
    }, []);


    useEffect(() => {
        if (!isMobile) {
            // Filtra gli elementi con inEvidenza = true
            const evidenza = portfolioCards.filter(card => card.inEvidenza);

            // Filtra gli altri elementi e ordina casualmente
            const restante = portfolioCards
                .filter(card => !card.inEvidenza)
                .sort(() => Math.random() - 0.5);

            setInEvidenza(evidenza);
            setBestEvents(restante);
        } else {
            setBestEvents(portfolioCards);
        }
    }, []);

    const handleCardClick = (id) => {
        setSelectedCard(id);
    };

    const selectedCardData = portfolioCards.find((card) => card.id === selectedCard);

    return(
        <Container maxWidth={'100vw'}
                   sx={{
                       position:'relative',
                       display: 'flex',
                       flexDirection: 'column',
                       justifyContent: 'center',
                       alignContent: isMobile ? 'center' : 'flex-start',
                       width: '100vw',
                       height: '100vh',
                       paddingLeft: '5% !important',
                       overflowX:'hidden'
                       //backgroundImage: `url("/images/portfolio/${selectedCardData?.background}")`  //
                   }}
        >
            {selectedCardData?.backgroundVideo ?
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: -2,
                    }}
                >
                    <iframe
                        src={`${selectedCardData.backgroundVideo}`}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '177.78vh', // Mantiene l'aspect ratio 16:9
                            height: '100vh',
                            minWidth: '100%',
                            minHeight: '100%',
                            border: 'none',
                        }}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        allowFullScreen
                    ></iframe>
                </div>

                :
                <img
                    src={`/images/portfolio/progetti/${selectedCardData?.background}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: -2, // Mantenere il video dietro agli altri contenuti
                    }}
                    alt={'background'}
                />
            }

            {/* Maschera PNG sopra il video */}
            <img
                src={isMobile ? '/images/portfolio-mobile-mask.png' : '/images/portfolio-desktop-mask.png'}
                alt="Maschera"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                    zIndex: -1, // Mantenere la maschera sopra il video ma sotto il contenuto
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: isMobile ? 'center' : 'flex-start',
                    rowGap: '1rem',
                    width: isMobile ? '90vw' : '40vw',
                    height: isMobile ? '30%' : '30%',
                    textAlign: isMobile ? 'center' : 'left',
                    marginTop:'5vh'
                }}
            >
                <Typography variant={'h4'}>{selectedCardData?.title || t("test.portfolio")}</Typography>
                <Typography variant={'body'}>
                    {t(`portfolio.progetti.${selectedCardData.translationKey}.description`)}
                    {selectedCardData?.description}
                </Typography>
                <Button
                    variant={'empty'} sx={{width: 'fit-content', alignSelf: isMobile ? 'center' : 'flex-start'}}
                    onClick={() => setModalOpen(true)}
                >
                    {t(`portfolio.action`)}
                </Button>
            </Box>
            {!isMobile &&
                <>
                    <Typography marginTop={'5vh'}>{t(`portfolio.evidenza`)}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start',
                            width: '100%',
                            marginTop: '1rem',
                            columnGap: '20px',
                            overflowX: 'auto', // Abilita lo scroll orizzontale
                            overflowY: 'hidden', // Evita lo scroll verticale
                            scrollbarWidth: 'thin', // Per i browser compatibili
                            '&::-webkit-scrollbar': { // Stile per Chrome/Safari
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': { // Stile per il cursore
                                background: '#ccc',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        {inEvidenza && inEvidenza.map((card) => (
                            <PortfolioCard
                                card={card}
                                key={card.id}
                                title={card.title}
                                isSelected={selectedCard === card.id}
                                onClick={() => handleCardClick(card.id)}
                            />
                        ))}
                    </Box>
                </>
            }
            <Typography marginTop={isMobile? '10vh' : '2vh'}>Best Events</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    width: '100%',
                    marginTop: '1rem',
                    columnGap: '20px',
                    overflowX: 'auto', // Abilita lo scroll orizzontale
                    overflowY: 'hidden', // Evita lo scroll verticale
                    scrollbarWidth: 'thin', // Per i browser compatibili
                    '&::-webkit-scrollbar': { // Stile per Chrome/Safari
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': { // Stile per il cursore
                        background: '#ccc',
                        borderRadius: '4px',
                    },
                }}
            >
                {bestEvents && bestEvents.map((card) => (
                    <PortfolioCard
                        card={card}
                        key={card.id}
                        title={card.title}
                        isSelected={selectedCard === card.id}
                        onClick={() => handleCardClick(card.id)}
                    />
                ))}
            </Box>

            {/* Modale Fullscreen */}
            <ProjectModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                card={selectedCardData}
                onCardSelect={(id) => handleCardClick(id)}
            />

        </Container>
    )
}

export default Portfolio
